import { Auth } from "aws-amplify";

export const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const getUserInfo = async () => {
  // Auth.currentUserInfo() get user attributes
  const info = await Auth.currentUserInfo();
  return info;
};
