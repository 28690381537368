import _, { toLower, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import { getTeam } from "../lib/automatic";
import Badge from "./Badge";
import LoadingSpinner from "./LoadingSpinner";
import { SelectColumnFilter } from "./table/Filter";
import Table from "./table/Table";

import seedData from "../seed/team.json";

const TeamTable = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [team, setTeam] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => {
          return value ? upperFirst(value) : "—";
        },
        width: 100,
        // className: "flex-none"
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          return value ? upperFirst(value) : "—";
        },
        width: 100,
        // className: "flex-none"
      },
      {
        Header: "Role",
        Filter: SelectColumnFilter,
        accessor: "role",
        Cell: ({ value }) => {
          return value === "admin" ? (
            <Badge status={value} />
          ) : (
            <Badge status={value} withIcon={false} />
          );
        },
        width: 100,
      },
      {
        Header: "Status",
        Filter: SelectColumnFilter,
        accessor: "userStatus",
        Cell: ({ value }) => {
          return <Badge status={toLower(value)} />;
        },
        width: 100,
      },
    ],
    []
  );

  useEffect(() => {
    // Use seed data if set to true
    if (process.env.REACT_APP_MOCK === "true") {
      setTeam(seedData);
      return;
    }

    // Retrieve team members and set to state
    trackPromise(
      getTeam().then((data) => {
        setTeam(data);
      })
    );
  }, []);

  return (
    <>
      {promiseInProgress === true ? (
        <LoadingSpinner item="Team" />
      ) : (
        <>
          <Table
            data={team}
            columns={columns}
            // linkPath={linkPath}
            resourceKey="id"
            // searchItems={searchItems}
            globalFilterPlaceholder={"Enter any team member name or email..."}
          />
        </>
      )}
    </>
  );
};

export default TeamTable;
