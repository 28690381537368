// Internal dependencies
import { formatAsCurrency } from "../lib/utility";
import ListItem from "../components/ListItem";
import Tooltip from "./Tooltip";

// External dependencies
import React from "react";
import _, { get, isEmpty } from "lodash";
import startCase from "lodash/startCase";
import { usePromiseTracker } from "react-promise-tracker";
import { DetailsPageCard } from "./DetailsPage";

const generatePropertyHeader = (property) => {
  try {
    switch (get(property, "type")) {
      case "vehicle":
        return (
          [
            _(property).get("data.year"),
            _(property).get("data.make"),
            _(property).get("data.model"),
          ]
            // Filter out null values
            .filter((i) => i)
            // Join label together
            .join(" ")
        );
      case "dwelling":
        return (
          [
            _(property).get("data.addressLine1"),
            _(property).get("data.addressLine2"),
            _(property).get("data.city"),
            _(property).get("data.state"),
            _(property).get("data.postalCode"),
          ]
            // Filter out null values
            .filter((i) => i)
            // Join label together
            .join(", ")
        );
      default:
        return "—";
    }
  } catch (error) {
    return "—";
  }
};

const generatePropertyDetails = (property, policy) => {
  try {
    let propertyDetails = [];

    // Get property type details
    switch (get(property, "type")) {
      case "vehicle":
        const result = propertyDetails.push([
          // Get VIN
          _(property)
            .chain()
            .get("data.vin")
            .thru((vin) => vin && `VIN: ${vin}`)
            .value(),
        ]);
        console.log("result: " + JSON.stringify(result));
        break;
      case "dwelling":
        // propertyDetails.push([
        //   [
        //     _(property).get("data.city"),
        //     _(property).get("data.state"),
        //     _(property).get("data.postalCode"),
        //     _(property).get("data.country"),
        //   ]
        //     // Filter out null values
        //     .filter((i) => i)
        //     // Join label together
        //     .join(" "),
        // ]);
        break;
      default:
        break;
    }

    // Find matching third party
    const thirdParties = _(policy)
      .chain()
      .get("thirdParties")
      .filter(
        (thirdParty) => _(thirdParty).get("property") === _(property).get("id")
      )
      .map((thirdParty) =>
        [
          _(thirdParty).chain().get("type").startCase().value(),
          _(thirdParty).get("name"),
        ]
          .filter((i) => i)
          .join(": ")
      )
      .value();
    propertyDetails.push(thirdParties);

    // Return array of details
    return propertyDetails;
  } catch (error) {
    return "—";
  }
};

const Policy = ({ policy }) => {
  const { promiseInProgress } = usePromiseTracker();
  let carrier = policy.carrier;
  return (
    <>
      {/* Policy Overview */}
      <DetailsPageCard title="Overview">
        <div className="flex flex-row md:divide-x gap-x-6">
          <div className="flex flex-col">
            <h3 className="uppercase text-[0.625rem] font-medium">
              Policy Type
            </h3>
            <p className="text-sm">
              {policy.type ? startCase(policy.type) : "—"}
            </p>
          </div>
          <div className="flex flex-col pl-6">
            <h3 className="uppercase text-[0.625rem] font-medium">Carrier</h3>
            <p className="text-sm">
              {policy.carrier ? startCase(carrier) : "—"}
            </p>
          </div>
          <div className="flex flex-col pl-6">
            <h3 className="uppercase text-[0.625rem] font-medium">
              Policy Number
            </h3>
            <p className="text-sm">{policy.policyNumber ?? "—"}</p>
          </div>
          <div className="flex flex-col pl-6">
            <h3 className="uppercase text-[0.625rem] font-medium">
              {policy.effectiveDate || !policy.expirationDate
                ? "Effective"
                : "Expires"}
            </h3>
            <p className="text-sm">
              {policy.effectiveDate &&
                new Date(policy.effectiveDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: "UTC",
                })}
              {policy.effectiveDate && policy.expirationDate && " - "}
              {policy.expirationDate &&
                new Date(policy.expirationDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: "UTC",
                })}
              {!policy.effectiveDate && !policy.expirationDate && "—"}
            </p>
          </div>
        </div>
      </DetailsPageCard>
      {/* Policy Address */}
      {!isEmpty(policy.address.addressLine1) && (
        <DetailsPageCard title="Mailing Address">
          <div className="flex flex-col ">
            <h3 className="text-sm font-medium">
              {policy.address.addressLine1}
            </h3>
            {!isEmpty(policy.address.addressLine2) && (
              <h3 className="text-sm font-medium">
                {policy.address.addressLine2}
              </h3>
            )}
            <h3 className="text-sm font-medium">
              {!isEmpty(policy.address.city) && policy.address.city + ", "}
              {policy.address.state} {policy.address.postalCode}
            </h3>
          </div>
        </DetailsPageCard>
      )}
      {/* Properties */}
      {!isEmpty(policy.properties) && (
        <DetailsPageCard title="Properties">
          <div className="flex flex-col divide-y gap-y-3">
            {policy.properties.map((property) => (
              <ListItem
                icon="Profile"
                itemHeader={generatePropertyHeader(property)}
                itemId={get(property, "id")}
                itemDetails={generatePropertyDetails(property, policy)}
              />
            ))}
          </div>
        </DetailsPageCard>
      )}
      {/* Insureds */}
      {!isEmpty(policy.insureds) && (
        <DetailsPageCard title="Insureds">
          <div className="flex flex-col divide-y gap-y-3">
            {policy.insureds.map((insured) => (
              <ListItem
                icon="Profile"
                itemHeader={`${insured.firstName} ${insured.lastName}`}
                itemDetails={[
                  // Get insured type
                  _(insured).chain().get("type").startCase().value(),
                  // Get insured dob
                  insured.dateOfBirth
                    ? "DOB: " +
                      new Date(insured.dateOfBirth).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        timeZone: "UTC",
                      })
                    : insured.dateOfBirthYear
                    ? "DOB: " + insured.dateOfBirthYear
                    : "",
                  // Get insured license details
                  insured.licenseNo
                    ? "License: " + insured.licenseNo
                    : insured.licenseState
                    ? "License: " + insured.licenseState
                    : insured.licenseStatus
                    ? "License status: " + insured.licenseStatus
                    : "",
                  // Find matching property
                  _(policy)
                    .chain()
                    .get("properties")
                    .find(
                      (property) =>
                        _(property).get("id") === _(insured).get("property")
                    )
                    .thru(
                      (property) =>
                        _(property).get("id") && (
                          <Tooltip
                            item={_(property)
                              .chain()
                              .get("id")
                              .thru((id) => id && `Property: ${id}`)
                              .value()}
                            tooltip={
                              <>
                                <strong>
                                  {[
                                    _(property).get("data.year"),
                                    _(property).get("data.make"),
                                    _(property).get("data.model"),
                                  ].join(" ")}
                                </strong>
                                <span>
                                  {_(property)
                                    .chain()
                                    .get("data.vin")
                                    .thru(
                                      (vin) =>
                                        vin &&
                                        `VIN: ${vin.substring(vin.length - 6)}`
                                    )
                                    .value()}
                                </span>
                              </>
                            }
                          />
                        )
                    )
                    .value(),
                ]}
              />
            ))}
          </div>
        </DetailsPageCard>
      )}
      {/* Coverages */}
      {!isEmpty(policy.coverages) && (
        <DetailsPageCard title="Coverages">
          <div className="flex flex-col divide-y gap-y-3">
            {policy.coverages.map((coverage) => (
              <ListItem
                icon="Profile"
                itemHeader={coverage.label ?? "Unknown Coverage Type"}
                itemDetails={[
                  // Get limit per person
                  _(coverage)
                    .chain()
                    .get("limitPerPerson")
                    .thru(
                      (limit) =>
                        limit && `${formatAsCurrency(limit)} per person`
                    )
                    .value(),
                  // Get limit per accident
                  _(coverage)
                    .chain()
                    .get("limitPerAccident")
                    .thru(
                      (limit) =>
                        limit && `${formatAsCurrency(limit)} per accident`
                    )
                    .value(),
                  // Get deductible
                  _(coverage)
                    .chain()
                    .get("deductible")
                    .thru(
                      (deductible) =>
                        (_.isNumber(deductible) || deductible) &&
                        `${formatAsCurrency(deductible)} deductible`
                    )
                    .value(),
                  // Find matching property
                  _(policy)
                    .chain()
                    .get("properties")
                    .find(
                      (property) =>
                        _(property).get("id") === _(coverage).get("property")
                    )
                    .thru(
                      (property) =>
                        _(property).get("id") && (
                          <Tooltip
                            item={_(property)
                              .chain()
                              .get("id")
                              .thru((id) => id && `Property: ${id}`)
                              .value()}
                            tooltip={
                              <>
                                <strong>
                                  {[
                                    _(property).get("data.year"),
                                    _(property).get("data.make"),
                                    _(property).get("data.model"),
                                  ].join(" ")}
                                </strong>
                                <span>
                                  {_(property)
                                    .chain()
                                    .get("data.vin")
                                    .thru(
                                      (vin) =>
                                        vin &&
                                        `VIN: ${vin.substring(vin.length - 6)}`
                                    )
                                    .value()}
                                </span>
                              </>
                            }
                          />
                        )
                    )
                    .value(),
                  ,
                ]}
              />
            ))}
          </div>
        </DetailsPageCard>
      )}
      {/* Third Parties */}
      {!isEmpty(policy.thirdParties) && (
        <DetailsPageCard title="Third parties">
          <div className="flex flex-col divide-y gap-y-3">
            {policy.thirdParties.map((thirdParty) => (
              <ListItem
                icon="Profile"
                itemHeader={_(thirdParty).get("name")}
                itemDetails={[
                  // Get third party type
                  _(thirdParty)
                    .chain()
                    .get("type")
                    .thru((type) => type && _(type).startCase())
                    .value(),
                  // Get third party address
                  [
                    _(thirdParty).get("address.addressLine1"),
                    _(thirdParty).get("address.addressLine2"),
                    _(thirdParty).get("address.city"),
                    _(thirdParty).get("address.state"),
                    _(thirdParty).get("address.postalCode"),
                  ]
                    .filter((i) => i)
                    .join(", "),
                  // Find matching property
                  _(policy)
                    .chain()
                    .get("properties")
                    .find(
                      (property) =>
                        _(property).get("id") === _(thirdParty).get("property")
                    )
                    .thru(
                      (property) =>
                        _(property).get("id") && (
                          <Tooltip
                            item={_(property)
                              .chain()
                              .get("id")
                              .thru((id) => id && `Property: ${id}`)
                              .value()}
                            tooltip={
                              <>
                                <strong>
                                  {[
                                    _(property).get("data.year"),
                                    _(property).get("data.make"),
                                    _(property).get("data.model"),
                                  ].join(" ")}
                                </strong>
                                <span>
                                  {_(property)
                                    .chain()
                                    .get("data.vin")
                                    .thru(
                                      (vin) =>
                                        vin &&
                                        `VIN: ${vin.substring(vin.length - 6)}`
                                    )
                                    .value()}
                                </span>
                              </>
                            }
                          />
                        )
                    )
                    .value(),
                ]}
              />
            ))}
          </div>
        </DetailsPageCard>
      )}
      {/* Documents */}
      {!isEmpty(get(policy, "documents")) && (
        <DetailsPageCard title="Documents">
          <div className="flex flex-col divide-y gap-y-3">
            {promiseInProgress === true ? (
              <>Loading</>
            ) : (
              Array.isArray(policy.documents) &&
              policy.documents.map((document) => (
                <ListItem
                  itemHeader={_(document).get("name") ?? "Document"}
                  itemId={"Open document"}
                  itemLink={_(document).get("url")}
                  itemDetails={[
                    _(document)
                      .chain()
                      .get("source")
                      .thru(
                        (source) => source && `Source: ${_(source).startCase()}`
                      )
                      .value(),
                    _(document)
                      .chain()
                      .get("createdAt")
                      .thru(
                        (date) =>
                          date &&
                          new Date(date).toLocaleString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            timeZone: "UTC",
                          })
                      )
                      .value(),
                  ]}
                ></ListItem>
              ))
            )}
          </div>
        </DetailsPageCard>
      )}

      {/* Policy Metadata*/}
      <DetailsPageCard title="Metadata">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row">
            <div className="flex flex-col w-44 gap-y-2 text-gray-600">
              <p className="text-sm">Created</p>
            </div>
            <div className="flex flex-col grow">
              <p className="text-sm">
                {_(policy).get("createdAt")
                  ? new Date(_(policy).get("createdAt")).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short",
                      }
                    )
                  : "—"}
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-44 gap-y-2 text-gray-600">
              <p className="text-sm">Last modified</p>
            </div>
            <div className="flex flex-col grow">
              <p className="text-sm">
                {_(policy).get("modifiedAt")
                  ? new Date(_(policy).get("modifiedAt")).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short",
                      }
                    )
                  : "—"}
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-44 gap-y-2 text-gray-600">
              <p className="text-sm">Last refreshed</p>
            </div>
            <div className="flex flex-col grow">
              <p className="text-sm">
                {_(policy).get("refreshedAt")
                  ? new Date(_(policy).get("refreshedAt")).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short",
                      }
                    )
                  : "—"}
              </p>
            </div>
          </div>
        </div>
      </DetailsPageCard>
    </>
  );
};

export default Policy;
