import { Combobox, Listbox, RadioGroup, Tab } from "@headlessui/react";
import _, { get } from "lodash";
import { React, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import Form from "../components/Form";
import Icon from "../components/Icon";
import Close from "../icons/Close";

import { getUserInfo } from "../lib/auth";
import { getTeam, inviteTeamMember } from "../lib/automatic";
const InviteTeamMember = () => {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  // Setup state
  const [error, setError] = useState(null);
  const [newMember, setNewMember] = useState({});
  const [team, setTeam] = useState([]);
  const [userId, setUserId] = useState({});

  useEffect(() => {
    trackPromise(
      getTeam().then((data) => {
        setTeam(data);
      })
    );

    getUserInfo().then((data) => {
      console.log(data);
      const currentUser = get(data, "username");
      setUserId(currentUser);
    });
  }, []);

  // Setup New Ignition Session form fields
  const inviteTeamMemberFields = [
    {
      id: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "Team member first name...",
    },
    {
      id: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Team member last name...",
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      placeholder: "Team member email address...",
    },
    {
      id: "role",
      label: "Role",
      type: "dropdown",
      values: ["member", "admin"],
      placeholder: "Team member role...",
    },
  ];

  // Create new member and send ignitionSession link on form submit; return to ignitionSessions
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      // Invite team member

      const isEmailTaken = _.some(team, { email: get(newMember, "email") });

      if (isEmailTaken) {
        throw new Error("Email already taken");
      }

      const member = await inviteTeamMember(newMember);

      // Inform dashboard member of success and navigate to new created session
      toast.success("Invite sent");
      navigate(`/team`);
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
      console.error(error);
    }
  };

  return (
    <div className="w-full h-full bg-white z-10 flex flex-col gap-y-2 absolute">
      <div className="p-8 flex items-center justify-between border-b border-gray-200">
        <h1 className="text-2xl font-bold">Invite Team Member </h1>
        <div className="flex gap-x-4 items-center">
          {/* <ButtonSecondary text="Send and create another" /> */}

          {/* <button onClick={() => toast.error("link sent")}>Make me a toast</button> */}
          {/* <div className="h-8 w-px mx-2 bg-gray-300 mx-"></div> */}
          <Icon
            icon={Close}
            height="h-4"
            width="w-4"
            className="cursor-pointer transition hover:scale-110"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="p-8 flex flex-col gap-y-8">
        {promiseInProgress === true ? <p>Loading...</p> : null}
        <Form
          name="invite-team-member"
          fields={inviteTeamMemberFields}
          formData={newMember}
          setFormData={setNewMember}
          onSubmit={onSubmit}
          error={error}
          button="Invite Team Member"
        />
      </div>
    </div>
  );
};

export default InviteTeamMember;
