import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AdminContext } from "../App";
import ButtonPrimary from "../components/ButtonPrimary";
import Callout from "../components/Callout";
import TeamTable from "../components/TeamTable";
import Add from "../icons/Add";

const AddTeamMember = () => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-2xl font-bold">Team</h1>
      <div className="flex gap-x-2">
        {/* <ButtonSecondary text="Filter" icon="Filter" /> */}
        <Link to="/team/invite">
          <ButtonPrimary text="Invite team member" icon={Add} />
        </Link>
      </div>
    </div>
  );
};

const Team = () => {
  const isAdmin = useContext(AdminContext);

  return (
    <div className="flex flex-col flex-1 h-full px-12 gap-y-8">
      {isAdmin ? (
        <>
          <AddTeamMember />
          <TeamTable />
        </>
      ) : (
        <Callout
          type="warning"
          message="Administrator access required to view Team table. Please contact your system admin."
        />
      )}
    </div>
  );
};

export default Team;
