import { Amplify } from "aws-amplify";

import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Toast from "./components/Toast";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { get } from "lodash";
import amplifyconfiguration from "./aws-exports";
import Topbar from "./components/Topbar";
import { getUserInfo } from "./lib/auth";
import { getClientConfig } from "./lib/automatic";
import { IS_LOCAL, STAGE } from "./lib/constants";

// Mutilate the amplify redirect urls and domain - don't worry just mutate 🤪
amplifyconfiguration.oauth.redirectSignIn = IS_LOCAL
  ? "http://localhost:3002/"
  : `https://dashboard${STAGE === "prod" ? "" : `.${STAGE}`}.axle.insure/`;
amplifyconfiguration.oauth.redirectSignOut = IS_LOCAL
  ? "http://localhost:3002/"
  : `https://dashboard${STAGE === "prod" ? "" : `.${STAGE}`}.axle.insure/`;
amplifyconfiguration.oauth.domain = "auth.axle.insure";

// Configure Amplify
Amplify.configure(amplifyconfiguration);

// Create context for the client
export const ClientContext = React.createContext({});
export const AdminContext = React.createContext({});

const Home = () => {
  const [clientConfig, setClientConfig] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  // Get client config on home page load
  useEffect(() => {
    getClientConfig("validation").then((data) => {
      setClientConfig(data);
    });

    getUserInfo().then((data) => {
      const currentRole = get(data, "attributes['custom:role']");
      setIsAdmin(currentRole === "admin" ? true : false);
    });
  }, []);
  return (
    <>
      <ClientContext.Provider value={clientConfig}>
        <AdminContext.Provider value={isAdmin}>
          <div className="flex h-full w-full">
            <Sidebar />
            <div className="flex flex-1 flex-col ml-52 relative">
              <Topbar />
              <Outlet />
              <Toaster position="bottom-right">
                {(t) => <Toast t={t} />}
              </Toaster>
            </div>
          </div>
        </AdminContext.Provider>
      </ClientContext.Provider>
    </>
  );
};

const Login = () => (
  <div className="flex items-center justify-center h-screen">
    <Authenticator
      loginMechanisms={["email"]}
      socialProviders={["google"]}
      hideSignUp={true}
    />
  </div>
);

function App() {
  const { route } = useAuthenticator((context) => [context.route]);
  return route === "authenticated" ? <Home /> : <Login />;
}

export default function AppWithProvider() {
  return (
    <Authenticator.Provider>
      <App></App>
    </Authenticator.Provider>
  );
}
